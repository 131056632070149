import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import EmptyData from "../../../../components/EmptyData";

const Table = ({examHistory, search}) => {  

  return (
    <React.Fragment>
        <div

          style={{ border: "1px solid #ccc" }}
        >
          {examHistory.length ?
          <div className="md:hidden mt-3 bg-white w-full p-4 rounded-md">
            {examHistory.map((exam, index) => (
                      <ul className="border border-gray-300 rounded-lg mb-8 p-0" key={index}>
                          <li className="flex justify-between py-3 px-4">
                            <span className="text-lg">Title:&nbsp;</span>
                            <span className="text-lg">
                              { exam?.exam?.title}
                            </span>
                          </li>
                          <li className="flex justify-between bg-gray-100 py-4 px-4">
                            <span className="text-lg">Client Name:&nbsp;</span>
                            <span className="text-lg">
                              { exam?.exam?.client?.name }
                            </span>
                          </li>
                          <li className="flex justify-between bg-gray-100 py-4 px-4">
                            <span className="text-lg">Status:&nbsp;</span>
                            <span className="text-lg">
                            {
                                (exam?.completed_at !== null)
                                ?  <span className="bi bi-check2-circle text-green-500 text-2xl"></span>
                                :  <span className="bi bi-x-octagon text-red-500 text-2xl"></span>
                              }                                 
                            </span>
                          </li>
                          <li className="flex justify-between py-4 px-4">
                            <span className="text-lg">Completed At&nbsp;</span>
                            <span className="text-lg">
                              { `${ new Date(exam?.completed_at).toLocaleDateString('EN-gb') }` }
                            </span>
                          </li>
                      </ul>
                ))  }                
        </div>
        : 
        <section className="md:hidden">
            <EmptyData 
              text={ 
                search !==  '' ? 'Sorry, no result found for your search'  : 
                'Sorry, you dont have any exam history yet!'
                }
            /> 
        </section>  
        }
         {
           ( examHistory.length ) ?
            <div class="flex bg-white flex-col overflow-x-auto m-hidden">
            <div class="sm:-mx-6 lg:-mx-8">
              <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                <div class="overflow-x-auto">
                  <table class="min-w-full text-left text-sm font-light">
                    <thead class="border-b font-medium dark:border-neutral-500">
                      <tr>                         
                        <th scope="col" class="px-6 py-4">S/N</th>                        
                        <th scope="col" class="px-6 py-4">Title</th>
                        <th scope="col" class="px-6 py-4">Client Name</th>
                        <th scope="col" class="px-6 py-4">Status</th>
                        <th scope="col" class="px-6 py-4">Completed At</th>                         
                      </tr>
                    </thead>
                    <tbody>                             
                      {
                        examHistory.length ? examHistory.map( (exam, id) => {
                          return(
                            <tr key={ id } className="border-b dark:border-neutral-500">                            
                              <td className="whitespace-nowrap pl-8  pr-6 py-4 font-medium"> {id + 1} </td>
                              <td className="whitespace-nowrap px-6 py-4"> { exam?.exam?.title} </td>
                              <td className="whitespace-nowrap px-6 py-4"> { exam?.exam?.client?.name } </td>
                              <td className="whitespace-nowrap px-6 py-4">  
                                  <span className="text-lg">
                                  {
                                      (exam?.completed_at !== null)
                                      ?  <span className="bi bi-check2-circle text-green-500 text-2xl"></span>
                                      :  <span className="bi bi-x-octagon text-red-500 text-2xl"></span>
                                    }
                                  </span> 
                               </td>
                              <td className="whitespace-nowrap px-6 py-4"> { `${ new Date(exam?.completed_at).toLocaleDateString('EN-gb') }` } </td>                     
                          </tr>
                          )
                          })
                          : null
                        }              
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
           </div>
           :
            <section className="max-[500px]:hidden">
                <EmptyData 
                    text={ 
                      search !== '' ? 'Sorry, no result found for your search'  : 
                      'Sorry, you dont have any exam history yet!'
                      }
                  />
            </section>                
          }           
        </div>
    </React.Fragment>
  );
};

export default Table;
