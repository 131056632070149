import React from 'react'

const Input = ({placeholder, className, ...rest}) => {
  return (
    <input 
       {...rest} className={`py-3 px-3 border w-full border-slate-300 bg-white ${className}`}
       placeholder={`🔍 ${placeholder}`}
    />
  )
}

export default Input