import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import UpcommingAssessment from "./components/UpcomingAssessment";
import MockAssessment from './components/MockAssessment';

const ViewAssessement = () => {      

  useEffect(() => {
      /*window.onbeforeunload = function(event) {
            event.preventDefault();
            event.returnValue = '';
          }*/
      sessionStorage.setItem('active-page-view', 'normal-exam')
    }, []);

  return (
    <div className='bg-whit'>       
        <section className='jumbotron bg-white p-4'>
            <Link to={'/candidate/assessments/view-assessments'} className='text-lg'>
              Exam
            </Link>
            <span className='mx-2'>/</span>
            <Link to={'/candidate/assessments/view-assessments'} className='text-lg text-customGreen'>
                 View Exams
            </Link>        
        </section>                    
          <div className='pb-3'>
             <UpcommingAssessment  />
          </div>               
    </div>
  )
}

export default ViewAssessement
