import React, { useMemo } from "react";
import { UpdatedRequestTable } from "../../../../components/table/RequestTable";
import EmptyData from "../../../../components/EmptyData";

function capitalizeText(str) {
  const newText = str.charAt(0).toUpperCase() + str.slice(1);
  return newText;
}

const Applications = ({ data, perPage, isLoading, search }) => {

  const statusColor = {
     pending: 'text-customBlue',
     qualified: 'text-customGreen',
     disqualified:'text-customBlue'//'text-customOrange',
  }

  let columns = useMemo(
    () => [
      {
        title: "ID",
        selector: null,
        key: "id",
        render: ({ index }) =>
          index +
          1 +
          ((data?.current_page || 1) - 1) *
            (data?.per_page || perPage),
      },
      {
        title: "Title",
        selector: null,
        key: null,
        render: ({ row }) => {
          return (
            <button
              className="text-baseFont"
            >
              {row?.campaign?.title} 
            </button>
          );
        },
      },
      {
        title: "Client Name",
        selector: null,
        key: null,
        render: ({ row }) => {
          return (
            <button
              className="text-baseFont"
            >
              {row?.campaign?.client?.name} 
            </button>
          );
        },
      },
      {
        title: "Application Status",
        selector: null,
        key: null,
        render: ({ row }) => {
            return (
              <span
                className={`text-baseFont border rounded-full py-1 block text-center w-[80px] ${
                   row?.status === 'qualified' ? 'text-customGreen border-customGreen' :
                   'text-customBlue border-customBlue'
                }`}
              >
                {row?.status === 'qualified' ? row?.status : 'pending'} 
              </span>
            );
          },
      }, 
   ], [data?.current_page, data?.per_page, perPage])


  if (data?.data?.length < 1) {
    return (
     <EmptyData
        text={ search === '' ?  "You don't have any Applications yet!" : 'No result found for your search'}
      />
    );
  }

  return (
    <div
      className="w-full mt-3 p-3 rounded-lg bg-white px-2 mb-1 overflow-x-scroll"
      // style={{ overflowX: width > 1350 ? null : "scroll" }}
    >
       <div className="md:hidden mt-3">
         {data?.data &&
                  data?.data?.map((application, index) => (
                     <ul className="border border-gray-200 rounded-lg mb-8 p-0">
                        <li className="flex justify-between py-3 px-4">
                           <span className="text-lg">Title:&nbsp;</span>
                           <span className="text-lg">
                              {application?.campaign?.title}
                           </span>
                        </li>
                        <li className="flex justify-between bg-gray-100 py-4 px-4">
                           <span className="text-lg">Company Name:&nbsp;</span>
                           <span className="text-lg">
                           {application?.campaign?.client?.name}
                           </span>
                        </li>
                        <li className="flex justify-between py-4 px-4">
                           <span className="text-lg">Status:&nbsp;</span>
                           <span className={`text-lg ${ statusColor[application?.status] }`}>
                                {
                                capitalizeText(application?.status).toLowerCase() === 'disqualified' ? 
                                 'Pending' : 
                                 capitalizeText(application?.status)
                               }
                           </span>
                        </li>
                     </ul>
              ))}                    
       </div>

        <section className="m-hidden">
          <UpdatedRequestTable
              columns={columns}
              data={data?.data}
              isLoading={isLoading}
        />  
        </section>    
    </div>
  );
};

export default Applications;
