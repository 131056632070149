import React, { useMemo, useState } from 'react'
import { UpdatedRequestTable } from '../../../../../../components/table/RequestTable';
import { convertTo12Hour } from '../../../../../../utils/dateTimeFormatter';
import { useNavigate } from 'react-router-dom';
import { clearFlaggedQuestion } from '../../../../../../features/candidate-exam/candidate-exam-slice';
import { useDispatch } from 'react-redux';
import BookexamModal from './BookexamModal';
import UnlockAssessmentModal from './unlockAssessmentModal';

const PhysicalExams = ({ 
           exams, isLoading, currentPage, setCurrentPage, perPage, setPerPage,
           refetch,
        }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [requireBooking, setRequireBooking] = useState(false)
    const [isLocked, setIsLocked] = useState(false)

    const handleActionClick = (row) => {
        sessionStorage.setItem('active-exam',  JSON.stringify(row))
         if(row?.require_booking === 1){
          setRequireBooking(true)
         }
         else {
           // exam is already booked
           if(row?.booked_at){
              // check if exam is locked
              if(row?.exam_is_locked) setIsLocked(true)
           } 
         }
    }

    let columns = useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 +
              ((exams?.current_page || 1) - 1) *
                (exams?.per_page || perPage),
          },
          /*{
            title: "Client Name",
            selector: "client_name",
            key: "client_name",
            render: ({ item }) => {
              return (
                <button
                  className="text-baseFont"
                >
                  {item} 
                </button>
              );
            },
          },*/
          {
            title: "Exam Name",
            selector: "exam_name",
            key: "exam_name",
          },
          {
            title: "Duration",
            selector: "exam_duration",
            key: "exam_duration",
            render: ({ item }) => {
                return (
                  <button
                    className="text-baseFont"
                  >
                    {item} mins 
                  </button>
                );
              },
          }, 
          {
            title: "Questions",
            selector: "number_of_questions",
            key: "number_of_questions",
            render: ({ item }) => {
                return (
                  <button
                    className="text-baseFont pl-3"
                  >
                    {item} 
                  </button>
                );
              },
          },
          {
            title: "Start Date",
            selector: "start_date",
            key: "start_date",
            render: ({item}) => {
              return <span>{`${ new Date(item).toLocaleDateString()}`}</span>
            }
          },
          {
            title: "End Date",
            selector: "end_date",
            key: "end_date",
            render: ({item}) => {
              return <span>{`${ new Date(item).toLocaleDateString()}`}</span>
            }
          },
          {
            title: "Start Time",
            selector: "start_time",
            key: "start_time",
            render: ({item}) => {
              return <span>{`${convertTo12Hour(item)}`}</span>
            }
          },
          {
            title: "End Time",
            selector: "end_time",
            key: "end_time",
            render: ({item}) => {
              return <span>{`${convertTo12Hour(item)}`}</span>
            }
          },
          {
            title: "Action",
            selector: "end_time",
            key: "end_time",
            render: ({item, row}) => {
              return <button 
                       className='bg-primary rounded-full text-white py-2 px-5 text-sm'
                       onClick={ () => handleActionClick(row) }
                      >
                        {
                           row?.require_booking !== 1 ? 
                             row?.exam_is_locked ? 'Unlock Exam' : 'Start Exam' :
                           'Book Exam'
                          }
                    </button>
            }
          },
     ], [exams?.current_page, exams?.per_page, perPage])

  return (
    <div className='p-3 rounded-lg bg-white'>
         { requireBooking ?
           <BookexamModal 
             closeModal={setRequireBooking} 
             refetch={refetch}
           />
           : null
         }
         {
           isLocked ?
             <UnlockAssessmentModal 
                refetch={refetch} closeModal={setIsLocked} 
             />
           : null
         }
        <div className='max-[500px]:hidden'>
          <UpdatedRequestTable
              columns={columns}
              data={exams?.data}
              isLoading={isLoading}
        />
        </div>
       <div className='md:hidden grid grid-cols-1 gap-5'>
           {
              exams?.data?.length ? 
               exams?.data?.map( exam => {
                 return (
                  <ul key={exam?.id} className="border border-gray-200 rounded-lg mb-8 p-0">
                  <li className="flex justify-between py-3 px-4">
                     <span className="text-lg">Exam Name:&nbsp;</span>
                     <span className="text-lg">
                        {exam?.exam_name}
                     </span>
                  </li>
                  <li className="flex justify-between bg-gray-100 py-4 px-4">
                     <span className="text-lg">Duration:&nbsp;</span>
                     <span className="text-lg">
                     {exam?.exam_duration}
                     </span>
                  </li>
                  <li className="flex justify-between py-4 px-4">
                     <span className="text-lg">Questions:&nbsp;</span>
                     <span className={`text-lg`}>
                        {exam?.number_of_questions}
                     </span>
                  </li>
                  <li className="flex  bg-gray-100 justify-between py-4 px-4">
                    <div className='flex items-center'>
                      <span className="text-lg">Start Date:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                          {`${ new Date(exam?.start_date).toLocaleDateString()}`}
                      </span>
                      </div>
                      <div className='flex items-center'>
                      <span className="text-lg">End Date:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                          {`${ new Date(exam?.end_date).toLocaleDateString()}`}
                      </span>
                      </div>
                  </li>
                  <li className="flex justify-between py-4 px-4">
                    <div className='flex items-center'>
                      <span className="text-lg">Start Time:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                       {`${convertTo12Hour(exam?.start_time)}`}
                      </span>
                      </div>
                      <div className='flex items-center'>
                      <span className="text-lg">End Time:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                        {`${convertTo12Hour(exam?.end_time)}`}
                      </span>
                      </div>
                  </li>
                  <li className="flex justify-between bg-gray-100 py-4 px-4">
                     <span className="text-lg">Action:&nbsp;</span>
                     <button 
                       className='bg-primary rounded-full text-white py-2 px-5 text-sm'
                       onClick={ () => handleActionClick(exam)}
                      >
                        {
                           exam?.require_booking !== 1 ? 
                             row?.exam_is_locked ? 'Unlock Exam' : 'Start Exam' :
                           'Book Exam'
                          }
                    </button>
                  </li>
               </ul>
                 )
               } )
              : null
           }
        </div>
    </div>
  )
}

export default PhysicalExams