import { createApiClient } from "./client";

const client = createApiClient()

export const exams = {

  getAssesmentsv2: ({ type, id, currentPage, perPage, search }) =>
        client.get(`/v2/candidate/${id}/exams?per_page=${perPage}&page=${currentPage}${
          type ? `&type=${type}` : ''
        }${
          search ? `&search=${search}` : ''
        }`)
       .then(({ data }) => data),
    
   getExamQuestionsv2: ({userId, examId, userExamId}) =>
    client
        .get(`/v2/candidate/${userId}/exams/${examId}/questions/${userExamId}`)
        .then(({ data }) => data),
 /* UPDATE ANSWERS */
   updateAnswersv2: ({data, userId, examId, userExamId}) =>
    client
      .post(
        `/v2/candidate/${userId}/exams/${examId}/questions/${userExamId}/save-answers`, data
      )
      .then(({ data }) => data),

    gradeAssessmentv2: ({ userId, examId, userExamId }) =>
    client
    .post(
    `/v2/candidate/${userId}/exams/${examId}/questions/${userExamId}/submit-exam`
    )
   .then(({ data }) => data),

   getExamsHistoryv2: ({id, currentPage, perPage, search}) =>
    client.get(`/v2/candidate/${id}/exams/history?per_page=${perPage}&page=${currentPage}${
          search ? `&search=${search}` : ''
        }`).then(({ data }) => data),

   getBookingsv2: (userId, examId) =>
    client
      .get(`/v2/candidate/${userId}/exams/${examId}/bookings`)
      .then(({ data }) => data),

    createBookingv2: ({ userId, examId, payload }) =>
      client
        .post(`/v2/candidate/${userId}/exams/${examId}/bookings`, { ...payload })
        .then(({ data }) => data),

    unlockAssessmentv2: (userId, examId, payload) =>
          client
            .post(`/v2/candidate/${userId}/exams/${examId}/bookings/activate`, {
              ...payload,
            })
            .then(({ data }) => data),
}