import React, { useState, useEffect } from "react";
import BioData from "./Biodata/BioData";
import Education from "./Education/Education";
import FileUpload from "./FileUpload/FileUpload";
import Experience from "./Experience/Experience";
import Nysc from "./Nysc/Nysc";
import toast from "react-hot-toast";
import { api } from "../../../api";
import { useQuery, useMutation } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EmailVerificationModal from "./EmailVerificationModal";
import { useCustomToast } from "../../../components/customtoast/CustomToastProvider";
import UserProfileProgress from "./UserProfileProgress";
import { useDispatch } from "react-redux";
import { updateProfileStatus } from "../../../features/candidate-exam/candidate-exam-slice";
import { profile } from "../../../api/profile";

const Profile = () => {
  
  const customToast = useCustomToast();
  const navigate = useNavigate();
  const { uid1, uid2 } = useParams();
  const location = useLocation();
  const currentRoute = location.pathname;
  const dispatch = useDispatch()
  const user = JSON.parse(sessionStorage.getItem("user"));
  const isApply = sessionStorage.getItem("noapply");

  const [haveExperience, setHaveExperience] = useState(false);
  const [noExperience, setNoExperience] = useState(false);
  const [haveDoneNysc, setHaveDoneNysc] = useState(false);
  const [noNysc, setNoNysc] = useState(false);
  const [openVericationModal, setOpenVericationModal] = useState(false);
  const [profileStatus, setProfileStatus] = useState(0);

  let LinkPayload = {
    uid1: uid1,
    uid2: uid2,
  };

  if (currentRoute === `/profile/${uid1}/${uid2}`) {
    const { isLoading, data, isError, error } = useQuery(
      ["direct-profile"],
      async () => {
        const res = await api.directLinkToProfile(LinkPayload);
        sessionStorage.setItem("userToken", res.data.token);
        sessionStorage.setItem("user", JSON.stringify(res?.data));
        return res;
      }
    );
  }

  const { data: experiences, refetch } = useQuery(
    "get-experiences",
    async () => await profile.getUserExperienceListv2({ id: user?.user?.user_id })
  );

  const {
    isLoading,
    data: biodata,
    refetch: refetchBiodata,
    isError,
    error,
  } = useQuery("biodata", async () => {
      return await profile.getUserBiodatav2({ id: user?.user?.user_id})
  });

  // const { data: educations, refetch: refetchEducation } = useQuery(
  //   "get-educations",
  //   api.getUserEducationList
  // );
  const { data: nyscData, refetch: refetchNysc } = useQuery(
    "get-nysc-data",
    async () => await profile.getNyscv2({ id: user?.user?.user_id })
  );
  const { data: educations, refetch: refetchEducation } = useQuery(
    "get-educations", async () => {
       return await profile.getUserEducationListv2(user?.user?.user_id)
    }
  );

  const { data, refetch: refetchStatus } = useQuery(
    ["get-status"],
    async () => {
      const res = await profile.getProfileStatusv2({ id: user?.user?.user_id});
      setProfileStatus(res.data);
      return res;
    },
    {
      onSuccess: res => {
         const status = res?.data?.profile_completion_percentage
         if(parseInt(status?.slice(0, status?.indexOf('%'))) >= 70 ){
          // dispatch( updateProfileStatus(true) )
         }
         else dispatch( updateProfileStatus(false) )
      }
    }
  );

  const validateProfileMutation = useMutation(profile.validateProfilev2, {
    onSuccess: (data) => {
       toast.remove()
       toast.success(data?.message)
       dispatch( updateProfileStatus(true) )
    },
    onMutate: () => {
      toast.loading('Saving Changes...')
    },
    onError: ({ response }) => {
      toast.remove();
      toast.error(response?.data?.message);
    },
  });

  const handleHaveExperience = () => {
    setHaveExperience(!haveExperience);
    setNoExperience(false);
  };

  const handleNoExperience = () => {
    setNoExperience(!noExperience);
    setHaveExperience(false);
  };
  const handleHaveDoneNysc = () => {
    setHaveDoneNysc(!haveDoneNysc);
    setNoNysc(false);
  };

  const handleNoNysc = () => {
    setNoNysc(!noNysc);
    setHaveDoneNysc(false);
  };

  const handleApply = async () => {
    let payload = {
      slug: user.slug || sessionStorage.getItem("slug"),
      referral: user.referral || sessionStorage.getItem("referral"),
    };
    try {
      // if (user.email_verified_at === null) {
      //   setOpenVericationModal(true)
      //   return
      // }
      toast.loading("loading...", {
        style: {
          container: "custom-toast-container",
        },
      });
      const res = await api.jobApplication(payload);
      toast.remove();
      customToast.success(res.message);
      if (res.success === true) {
        navigate("/candidate/dashboard");
      }
      sessionStorage.setItem("noapply", "no-application");
      sessionStorage.removeItem("slug");
      sessionStorage.removeItem("referral");
    } catch (error) {
      toast.remove();
      customToast.error(error.response.data.error);
    }
  };

  const updateProfile = () => {
     const candidateBiodata = biodata?.data;
     const candidateEducation = educations?.data?.length ? educations?.data[0] : []
     if(!candidateBiodata?.name){
       toast.error('Error! candidate name is missing.')
     }
     else if(!candidateBiodata?.email){
        toast.error('Error! candidate email is missing.')
     }
     else if(!candidateBiodata?.phone_number){
      toast.error('Error! candidate phone number is missing.')
     }
     else if(!candidateBiodata?.gender){
      toast.error('Error! candidate gender field is missing.')
     }
     else if(!candidateBiodata?.date_of_birth){
      toast.error('Error! candidate date of birth is missing.')
     }
     else if(!candidateBiodata?.state_of_origin) {
      toast.error('Error! candidate state of origin is missing.')
     }
     else if(!candidateBiodata?.state_of_residence){
      toast.error('Error! candidate state of residence is missing.')
     }
     else if(!candidateEducation?.class_of_degree?.name){
        toast.error('Error! candidate class of degree is missing')
     }
     else if(!candidateEducation?.course?.title){
      toast.error('Error! candidate course of study is missing')
     }
     else if(!candidateEducation?.degree?.name){
      toast.error('Error! candidate degree field is missing')
     }
     else if(!candidateEducation?.institution?.name){
      toast.error('Error! candidate institution field is missing')
     }
     else {
       const userId = JSON.parse(sessionStorage.getItem("user"))?.user?.user_id;
       validateProfileMutation.mutate(userId)
    }
  }

  return (
    <div className="max-w-[1368px] bg-white -mt-2 p-4 md:p-16 rounded-lg">
      {openVericationModal && (
        <EmailVerificationModal closeModal={setOpenVericationModal} />
      )}
      {profileStatus < "80%" && (
        <h1 className="font-semibold text-[#F35B24] text-2xl py-4">
          Update your profile to complete application.
        </h1>
      )}
      {!isApply && user?.campaign_title !== null && (
        <div>
          {" "}
          <div className="bg-[#413659] md:-mt-8 mt-3 text-white p-6 rounded-lg mb-10">
             My Profile
          </div>
        </div>
      )}
      <div className="w-full md:flex gap-x-10">
        <div className="w-full md:w-2/5 relative max-w-xs p-6 overflow-hidden bg-[#F2F3F7]  rounded-xl h-fit mb-10 md:mb-0 ">
          <UserProfileProgress profileStatus={profileStatus} />
        </div>
        <div className="w-full md:w-3/5">
          <div className="">
            <div className="">
              <BioData 
                 refetchStatus={refetchStatus} 
                 isLoading={isLoading}
                 isError={isError}
                 refetch={refetchBiodata}
                 error={error}
                 biodata={biodata}
              />
            </div>
            <Education
               refetchStatus={refetchStatus}
               educations={educations}
               refetchEducation={refetchEducation}
              // key={educations}
            />
            {/* <FileUpload /> */}
            {experiences?.data?.length === 0 ? (
              <div className="my-10">
                <div className=" flex items-center gap-x-4 mb-10">
                  <div className="w-1/3">
                    <p className="text-base font-medium">
                      Do you have any work Experience?
                    </p>
                  </div>
                  <div className="w-2/3 flex gap-x-6 items-center">
                    {" "}
                    <div className="">
                      <label
                        htmlFor="experience"
                        className="inline-flex items-center"
                      >
                        <input
                          type="checkbox"
                          id="experience"
                          checked={haveExperience}
                          onChange={handleHaveExperience}
                          className="form-checkbox h-5 w-5 text-primary"
                        />
                        <span data-testid="yes" className="ml-2 text-sm">Yes</span>
                      </label>
                    </div>
                    <div className="">
                      <label
                        htmlFor="no-experience"
                        className="inline-flex items-center"
                      >
                        <input
                          type="checkbox"
                          id="no-experience"
                          checked={noExperience}
                          onChange={handleNoExperience}
                          className="form-checkbox h-5 w-5 text-primary"
                        />
                        <span className="ml-2 text-sm">No</span>
                      </label>
                    </div>
                  </div>
                </div>
                {haveExperience && (
                  <Experience
                    experiences={experiences}
                    refetchStatus={refetchStatus}
                    refetch={refetch}
                    key={experiences}
                  />
                )}
                {noExperience && (
                  <div className="text-primary flex text-center text-base">
                     <span className="bi bi-check-circle">
                       &nbsp; Applied
                     </span>
                  </div>
                )}
              </div>
            ) : (
              <Experience
                experiences={experiences}
                refetch={refetch}
                refetchStatus={refetchStatus}
                key={experiences}
              />
            )}

            <div className="my-10">
              {nyscData?.data === null ? (
                <div>
                  <div className="flex items-center gap-x-4 mb-10">
                    <div className="w-1/3">
                      {" "}
                      <p className="text-base font-medium" data-testid="nysc status">
                        Are you done with Nysc?
                      </p>
                    </div>
                    <div className="w-2/3 flex gap-x-6 items-center">
                      <div className="">
                        <label
                          htmlFor="experience"
                          className="inline-flex items-center"
                        >
                          <input
                            type="checkbox"
                            id="experience"
                            checked={haveDoneNysc}
                            onChange={handleHaveDoneNysc}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-sm" data-testid="confirm-nysc-status">Yes</span>
                        </label>
                      </div>
                      <div className="">
                        <label
                          htmlFor="no-experience"
                          className="inline-flex items-center"
                        >
                          <input
                            type="checkbox"
                            id="no-experience"
                            checked={noNysc}
                            onChange={handleNoNysc}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          <span className="ml-2 text-sm">No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  {haveDoneNysc && (
                    <Nysc
                      key={nyscData}
                      nyscData={nyscData}
                      refetchNysc={refetchNysc}
                      refetchStatus={refetchStatus}
                    />
                  )}
                  {noNysc && (
                    <div className="text-primary text-center text-base">
                       <span className="bi bi-check-circle">
                         &nbsp; Applied
                       </span>
                    </div>
                  )}
                </div>
              ) : (
                <Nysc
                  nyscData={nyscData}
                  refetchNysc={refetchNysc}
                  refetchStatus={refetchStatus}
                  key={nyscData}
                />
              )}
            </div>

            <div>
              {/* && educations?.data?.length >= 1 && */}
              {
                // (educations?.data?.length >= 1 && experiences?.data?.length >= 1 ? (
                <div className="flex md:justify-end">
                  <button
                    onClick={updateProfile}
                    className="bg-primary text-white px-12 py-3 font-medium text-md rounded-lg"
                  >
                    Save My Changes
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
