import React, { useState } from "react";
import BiodataModal from "./EditBiodata";
import EditIcon from "../../../../assets/icons/edit-2.svg";
import AddIcon from "../../../../assets/icons/add-square.svg";
import ProfileImage from "../../../../assets/icons/profile-Img.svg";

import { api } from "../../../../api";
import { useQuery } from "react-query";
import moment from "moment";

import toast from "react-hot-toast";

function BioData({ refetchStatus, isLoading, biodata, refetch, isError, error }) {

  const [openEditBioModal, setOpenEditBioModal] = useState(false);
  const [addBioModal, setAddBioModal] = useState(false);
  const [biodatas, setBioData] = useState(biodata?.data);

  return (
    <div className="mb-10">
      <div className="flex gap-x-6 items-center mb-8">
        <div className="font-semibold text-xl text:darkBlue text-">Biodata</div>
        <div
          onClick={() => {
            setBioData(biodata);
            setOpenEditBioModal(true);
          }}
          className="cursor-pointer border border-primary text-primary p-2 rounded-lg text-base font-normal"
          data-testid="biodata"
        >
          Update biodata
          {/* <img src={EditIcon} alt="" /> */}
        </div>
        {/* <div onClick={() => setAddBioModal(true)} className="cursor-pointer">
          <img src={AddIcon} alt="" />
        </div> */}
        {openEditBioModal && (
          <BiodataModal
            closeModal={setOpenEditBioModal}
            biodata={biodatas?.data}
            refetch={refetch}
            refetchStatus={refetchStatus}
            isLoading={isLoading}
            isError={isError}
            error={error}
          />
        )}
        {/* {addBioModal && <AddBiodata closeModal={setAddBioModal} />} */}
      </div>
      <div className="">
        {/* <div className=" h-44 w-32 md:h-60 md:w-60  bg-gray-500 rounded-lg">
          <img
            src={`https://cdn.icon-icons.com/icons2/2506/PNG/512/user_icon_150670.png`}
            alt="profile-img"
            className="w-full h-full object-cover"
          />
        </div> */}
        <div className=" py-2 w-full">
          {/* <p className="font-bold text-base text-darkBlue mb-2">#00001</p> */}
          <div className="flex w-96 gap-x-10 mb-6">
            <div>
              <h2 className="text-xl md:text-2xl font-semibold text-dark-blue">
                {biodata?.data.name}
              </h2>
            </div>
            {/* <div className="rounded-full px-4 bg-[#ECFFCC] border border:darkBlue text-center">
                Pass
              </div> */}
          </div>
          <div className="w-full md:flex md:justify-between">
            {/* <div className="mb-2">
              <span className="text-xs text-lightGrey font-medium">
                Status:
              </span>{" "}
              <span className="text-sm text-[#87AA4B] font-bold">Active</span>
            </div> */}
            <div className="w-full md:w-1/2">
              <div className="mb-4">
                <span className="text-base text-lightGrey font-normal">
                  Email:
                </span>{" "}
                <span className="text-base font-medium text-darkBlue">
                  {biodata?.data.email}
                </span>
              </div>
              <div className="mb-4">
                <span className="text-base text-lightGrey font-normal">
                  Phone:{" "}
                </span>{" "}
                <span className="text-base font-medium text-darkBlue">
                  {biodata?.data.phone_number}
                </span>
              </div>

              <div className="mb-4">
                <span className="text-base text-lightGrey font-normal">
                  Date of Birth:
                </span>{" "}
                <span className="text-base font-medium text-darkBlue">
                  {biodata?.data.date_of_birth
                    ? moment(biodata?.data.date_of_birth).format("DD-MM-YYYY")
                    : null}
                </span>
              </div>
            </div>

            <div className="w-full md:w-1/2">
              <div className="mb-4">
                <span className="text-base text-lightGrey font-normal">
                  State of origin:
                </span>{" "}
                <span className="text-base font-medium text-darkBlue">
                  {biodata?.data.state_of_origin}
                </span>
              </div>
              <div className="mb-4">
                <span className="text-base text-lightGrey font-normal">
                  State of residence:
                </span>{" "}
                <span className="text-base font-medium text-darkBlue">
                  {biodata?.data.state_of_residence}
                </span>
              </div>
              <div className="mb-4">
                <span className="text-base text-lightGrey font-normal">
                  {" "}
                  Gender:
                </span>
                <span className="text-base font-medium text-darkBlue">
                  {biodata?.data.gender}
                </span>
              </div>
            </div>
          </div>
          {/* <div className="bg-[#87AA4B] p-4 rounded-lg text-white max-w-[11rem] text-sm">
              Performance Report
            </div> */}
        </div>
      </div>
    </div>
  );
}

export default BioData;
