import React from "react";
import ChangePasswordForm from "../../account/ChangePassword";

const Settings = () => {
  return (
    <div className="max-w-[1368px] bg-white mt-20 p-4 md:p-16 rounded-lg">
      <div className="flex">
        <div className="mx-auto md:w-1/2">
          <ChangePasswordForm />
        </div>
      </div>
    </div>
  );
};

export default Settings;
