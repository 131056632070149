import React, { useState } from 'react'
import Applications from './components/Applications';
import { useQuery } from 'react-query';
import { api } from '../../../api';
import Pagination from '../../../components/Pagination/Pagination';
import Input from '../../../components/input/Input';

const Application = () => {

  const [perPage, setPerPage] = useState(5); 
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('')
  const user = JSON.parse(sessionStorage.getItem('user'))

  const { data, isLoading, isError } = useQuery(
    ["applications", currentPage, perPage, search ],
    async () => {
      const data = await api.getApplications(
        { perPage, currentPage, id: user?.user?.user_id, search }
      );
      return data.data;
    },
    {
      cacheTime: 30000,
      staleTime: 30000,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      select: response => response?.data
    }
  );

  return (
    <div className='p-5'>
        {/*APPLICATIONS*/}
        <div className="w-full rounded-lg">
            <header className="bg-darkBlue md:flex justify-between items-center  py-3 px-4 rounded-md mb-4">
            <h1 className="text-white max-[500px]:mb-3 text-lg">
                Applications &nbsp;
                ({data?.total})
            </h1>
            <div className="w-[300px]">
                <Input 
                  type='search'
                  placeholder="Search applications"
                  onChange={ event => setSearch(event.target.value)}
                />
            </div>
            </header>
            <section>
                <Applications search={search} data={data} perPage={perPage} isLoading={isLoading} />
                <Pagination
                    data={data}
                    perPage={perPage}
                    pageNo={currentPage}
                    setPageNo={setCurrentPage}
                    setPerPage={setPerPage}
                />
            </section>
        </div>
    </div>
  )
}

export default Application