import React, { useState } from "react";
import EditExperienceModal from "./EditExperienceModal";
import EditIcon from "../../../../assets/icons/edit-2.svg";
import AddExperience from "./AddExperience";
import { MdWorkHistory } from "react-icons/md";
import moment from "moment";

function Experience({ experiences, refetch, closeModal, refetchStatus }) {
 
  const [openExperienceModal, setOpenExperienceModal] = useState(false);
  const [addExperienceModal, setAddExperienceModal] = useState(false);
  const [data, setData] = useState(experiences?.data[0]);

  return (
    <div className="mb-10">
      <div className="mb-2">
        <div className="font-semibold text-xl text:darkBlue flex items-center gap-x-24">
          Experience
          <div
            className="cursor-pointer border-primary border p-2 rounded-lg text-base text-primary font-normal"
            onClick={() => setAddExperienceModal(true)}
            data-testid="add-work-experience"
          >
            Add work experience
            {/* <img src={AddIcon} alt="" /> */}
          </div>
        </div>
        {openExperienceModal && (
          <EditExperienceModal
            closeModal={setOpenExperienceModal}
            data={data}
            refetch={refetch}
            refetchStatus={refetchStatus}
          />
        )}
        {addExperienceModal && (
          <AddExperience
            closeModal={setAddExperienceModal}
            refetch={refetch}
            refetchStatus={refetchStatus}
          />
        )}
      </div>{" "}
      {experiences?.data.length === 0 && (
        <div className="text-center text-base font-medium text-lightGrey">
          Please add experience
        </div>
      )}
      {experiences?.data.map((experience, index) => (
        <div key={index} className="">
          <div className="mb-3 flex justify-between items-center">
            <div className="w-[800px]">
              <div
                className="mb-3 flex justify-between items-center"
                key={experience.id}
              >
                <div className="flex gap-x-4">
                  <div className="h-16 bg-gray-300 p-2 flex justify-start rounded">
                    <MdWorkHistory className="text-[3rem]" />
                  </div>
                  <div className="">
                    <div className="font-medium text-xl text-darkBlue">
                      {experience.name}
                    </div>
                    <div className="font-normal text-lg">{experience.role}</div>
                    <div className="font-normal text-lg">
                      <span>{moment(experience.start_date).format("yyy")}</span>{" "}
                      -{" "}
                      {experience.end_date === null ? (
                        "Present"
                      ) : (
                        <span>{moment(experience.end_date).format("yyy")}</span>
                      )}
                    </div>
                    <div>
                      <p>{experience.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="font-normal text-lg">
              <span>{moment(experience.start_date).format("yyy")}</span> -{" "}
              {experience.end_date === null ? (
                "Present"
              ) : (
                <span>{moment(experience.end_date).format("yyy")}</span>
              )}
            </div> */}

            {/* <div className="">
            <div className="mb-2 flex items-center gap-x-6">
              <div className=" text-darkBlue text-sm font-light">
                {experience.name}
              </div>
              <div className="text-xs  font-medium text-lightGrey flex gap-x-2 items-center ">
                <div className="bg-gray-400 rounded-full h-1.5 w-1.5"></div>
                {experience.role}
              </div>
            </div>
          </div>
          <div>
            <p>{experience.description}</p>
          </div> */}
            <div className="flex justify-end items-center gap-x-4">
              <div
                onClick={() => {
                  setData(experience);
                  setOpenExperienceModal(true);
                }}
                className="cursor-pointer"
              >
                <img src={EditIcon} alt="" className="" />
              </div>
            </div>
          </div>
          <hr className="py-4" />
        </div>
      ))}
    </div>
  );
}

export default Experience;
