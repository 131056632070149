import React from 'react'
import BlockchainImg from '../images/Blockchain.png'

const EmptyData = ({text, width, height, containerWidth, containerClassName}) => {
  return (
    <div style={{width: containerWidth}} className={`py-12 ${containerClassName}`}>
       <img 
          src={BlockchainImg} alt="placeholder image"
          className='w-[290px] h-[210px] block mx-auto mt-5'
          style={{width, height}}
       />
       <p className='mt-2 text-lg font-light text-center pb-3'>{text}</p>
    </div>
  )
}

export default EmptyData