import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { api } from "../../../../../../api";
import { useMutation, useQuery } from "react-query";
import { useSelector, useDispatch } from "react-redux";
import { ScaleLoader } from "react-spinners";
import { AssessementContext } from "../../../AssessmentContext";
import { toast } from "react-hot-toast";
import BookexamModal from "./BookexamModal";
import DropdownMenu, {
  DropdownItem,
  DropdownItemGroup,
} from "@atlaskit/dropdown-menu";
import { updatedSavedAnswerPayload } from "../../candidate-assessment/components/utils/transformSavedAnswerFormat";
import { flushSubmittedAnswers } from "../../../../../../features/candidate-exam/candidate-exam-slice";
import { toPlainAnwers } from "../../candidate-assessment/components/utils/functions";
import Loader from "../../../../../../components/loader/Loader";
//import { flushSubmittedAnswers } from "../../../../../features/candidate-exam/candidate-exam-slice";

const MockAssessment = () => {

  const { assessment, setAssessment } = useContext(AssessementContext);
  const navigate = useNavigate();
  const [hasExams, setHasExams] = useState(true);
  const [viewBookingModal, setViewBookingModal] = useState(false);
  const dispatch = useDispatch()
  const { questionSavedAnswers, questionsDuration, examQuestions } = useSelector( state => state.candidateExam)
  const last_assessment_id = sessionStorage.getItem('last_asessement_id')
  const last_asessement_user_group_id = sessionStorage.getItem('last_asessement_user_group_id')

  const mutation = useMutation(api.getUserGroupAssesment, {
    onSuccess: (data) => {
      setAssessment({
        ...assessment,
        assessements: { ...data?.data },
        activeQuestion: { ...data?.data?.questions[0] },
        questionType: data?.data?.questions[0]?.type,
        activeAssessment: {
          ...JSON.parse(window.sessionStorage.getItem("active-assessment")),
        },
      });
    },

    onError: (errorMessage) => {
      if (errorMessage.toString().includes("400")) {
        toast.error("Assessment needs to be unlocked by OTP or and admin");
      }
    },
  });

  const {
    isLoading,
    data: mockAssessment,
    refetch,
    isError,
    error,
  } = useQuery(
    "user-group-practice-assessment",
    async () => {
      const data = await api.getPracticeExams();
      return data;
    },
    {
      onSuccess: (data) => {
        if (data?.data?.upcoming_exams?.length) {
          setHasExams(false);
        }
        //setAssessment({ ...assessment, viewAsessments: { ...data?.data } });
      },
      select: response => response?.data?.practice_exams
    },
     
  );

  const updateAnswersMutation = useMutation(api.updateAnswers,{
    onSuccess : (response) => {
        dispatch( flushSubmittedAnswers() ) 
        refetch()        
    },
 })

  const updateAnswers = (answers, questions) => {
    updateAnswersMutation.mutate({
      user_group_id: last_asessement_user_group_id,
      assessment_id: last_assessment_id,
      data: {
         ...updatedSavedAnswerPayload(
            [...answers.map( answer => {
            return {
               duration : answer?.duration,
               answer_id : answer?.answer_id ,
               question_id: answer?.question_id,
               answer_text: answer?.answer_text,    
            }
            })], 
            questionsDuration,  
            questions,
         ),
          time_left: parseInt(sessionStorage.getItem('client_side_time_left')),
         }
       })
     }

  const getQuestionsmutation = useMutation(api.getExamQuestions, {
    onSuccess: (data) => {
      //compare between current question and prevoius saved questions
      const questions = data?.data?.questions?.filter( q => {
         return q?.answers?.some( ans => ans?.status === true )
      })
      if(examQuestions?.length){
         if(questions?.length === 0 ){
            //sync client answers
            const client_answers = toPlainAnwers(examQuestions)
            updateAnswers(client_answers,data?.data?.questions)
         }
         else{
            //check for unsync questions and retry submitting
            const client_answers = toPlainAnwers(examQuestions)
            const server_answers = toPlainAnwers(questions)
            const unsynced_answers = client_answers.filter((client_answer) => {
              return !server_answers.some((server_answer) => {
                  return ( 
                           (client_answer.question_id === server_answer.question_id)
                                               &&
                           (client_answer.answer_id === server_answer.answer_id)
                        );
              } )
            });
           //update answers
            if(unsynced_answers?.length) updateAnswers(unsynced_answers,data?.data?.questions)
         }   
      }
    }
  });

  React.useEffect( () => {
    //update unsubmitted answers to sync with the backend.
      if(last_assessment_id){
          getQuestionsmutation.mutate({
            assessmentId: last_assessment_id ,
            groupId: last_asessement_user_group_id
          })
     }
   }, [] )

  const handleUserId = (exam) => {
    sessionStorage.setItem("user_group_id", exam.user_group_id);
    sessionStorage.setItem('active-candidate-exam', JSON.stringify(exam))
    sessionStorage.setItem('practice-exams', JSON.stringify(exam))
    sessionStorage.setItem("exam-type", 'mock');
    sessionStorage.setItem("group_id", exam.group_id);
  };

  const handleClickExam = (exam) => {
  
    sessionStorage.setItem("group_id", exam.group_id);
    sessionStorage.setItem("user_group_id", exam.user_group_id);
    sessionStorage.setItem('practice-exams', JSON.stringify(exam))
    sessionStorage.setItem('active-candidate-exam', JSON.stringify(exam))
    sessionStorage.setItem("exam-type", 'mock');
    setViewBookingModal(true);
  };
  return (
    <>    
      {" "}  
      {mutation.isLoading || isLoading ? (
         <Loader />
      ) :
      <div>
      <div className="text-white bg-darkBlue mt-8 mb-5 py-5 px-4 mx-1 rounded-lg">
        My Mock/Practice Exams&nbsp; {
          mockAssessment?.length ? 
          `(${mockAssessment?.length})`: null
        }
      </div>

      { !isLoading && mockAssessment?.length ? 
          <>
          { viewBookingModal && (
                <BookexamModal
                  refetch={refetch}
                  // getAssessmentMutation={getAssessmentMutation}
                  closeModal={setViewBookingModal}
                />
            )}
            {/** for mobile devices */}
            <div className="md:hidden">
            {
              mockAssessment?.length ?
                 mockAssessment?.map((exam, id) => {
                     return(
                        <ul key={id} className="border border-gray-300 rounded-lg mb-8 p-0">
                        <li className="flex justify-between py-3 px-4">
                          <span className="text-lg">Group Name:&nbsp;</span>
                          <span className="text-lg">
                             {exam?.group_name}
                          </span>
                        </li>
                        <li className="flex justify-between bg-gray-100 py-4 px-4">
                          <span className="text-lg">Client Name:&nbsp;</span>
                          <span className="text-lg">
                             {exam?.client_name}
                          </span>
                        </li>
                        <li className="flex justify-between py-4 px-4">
                          <span className="text-lg">Number Of Assessments:&nbsp;</span>
                          <span className="text-lg">
                             {exam?.number_of_assessments}
                          </span>
                        </li>
                        <li className="flex justify-between py-4 px-4 bg-gray-100">
                          <span className="text-lg">Booking Status:&nbsp;</span>
                          <span className="text-lg">
                             {
                                  (exam?.booking_status === 1 || exam?.require_booking === 0 )
                                   ?   <span className="bi bi-check-circle text-green-500 text-2xl"></span>
                                   :  <span className="bi bi-x-octagon text-red-500 text-2xl"></span>
                                }
                          </span>
                        </li>
                        <li className="flex justify-between py-4 px-4">
                          <span className="text-lg">Action:&nbsp;</span>
                          <span className="text-lg">
                            { exam?.require_booking ?
                                  <DropdownMenu trigger="More" >
                                      <DropdownItemGroup>
                                        <DropdownItem>
                                          <Link
                                            onClick={() => handleUserId(exam)}
                                            to={`/candidate/assessments/candidate-exams/${exam?.group_id}`}
                                            className="py-3 text-lg px-5"
                                          >
                                            &nbsp;View Exams
                                          </Link>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <button
                                              onClick={() => handleClickExam(exam)}
                                              className="text-lg py-2 px-5 rounded-lg"
                                            >
                                            {exam?.booking_status === 1 ? 'Update Booking' : 'Book Exam'  }
                                            </button>
                                        </DropdownItem>                          
                                      </DropdownItemGroup>
                                    </DropdownMenu> 
                                      :
                                      <Link
                                          onClick={() => handleUserId(exam)}
                                          to={`/candidate/assessments/candidate-exams/${exam?.group_id}`}
                                          className="py-3 bg-customGreen text-white  rounded-lg text-md px-8"
                                        >
                                          &nbsp;View Examsssss
                                    </Link>
                                   }          
                          </span>
                        </li>
                    </ul>
                     )
                 })   
                : null}              
            </div>
            {/** for laptop and desktop devices */}
           <div class="flex flex-col overflow-x-auto m-hidden">
              <div class="sm:-mx-6 lg:-mx-8">
                <div class="inline-block min-w-full py-2 sm:px-6 lg:px-8">
                  <div class="overflow-x-auto">
                    <table class="min-w-full text-left border border-gray-200 text-sm font-light">
                      <thead class="shadow font-medium dark:border-neutral-500">
                        <tr>                          
                          <th scope="col" className="px-6 py-6">S/N</th>
                          <th scope="col" className="px-6 py-6">Group Name</th>
                          <th scope="col" className="px-6 py-6">Client Name</th>
                          <th scope="col" className="px-6 py-6">Assessments</th>
                          <th scope="col" className="px-6 py-6">Booking Status</th>
                          <th scope="col" className="px-6 py-6">Action</th>               
                        </tr>
                      </thead>
                      <tbody>
                      {
                          mockAssessment?.length ?
                            mockAssessment?.map((exam, id) => {
                           return (
                            <tr class="border-b border-gray-200" key={id}>                             
                            <td class="whitespace-normal px-6 py-4">{id + 1}</td>
                            <td class="whitespace-normal px-6 py-4">{exam?.group_name}</td>
                            <td class="whitespace-normal px-6 py-4">{exam?.client_name}</td>
                            <td class="whitespace-normal pl-[50px]  pr-6 py-4">{exam?.number_of_assessments}</td>
                            <td class="whitespace-normal pl-6 pr-6 py-4">
                                {
                                  (exam?.booking_status === 1 || exam?.require_booking === 0 )
                                    ?   <span className="bi bi-check-circle text-green-500 text-2xl"></span>
                                    :  <span className="bi bi-x-octagon text-red-500 text-2xl"></span>
                                }
                            </td>
                            <td class="whitespace-nowrap px-6 py-4">
                                {exam?.require_booking ?
                                 <DropdownMenu trigger="More">
                                    <DropdownItemGroup>
                                      <DropdownItem>
                                        <Link
                                          onClick={() => handleUserId(exam)}
                                          to={`/candidate/assessments/candidate-exams/${exam?.group_id}`}
                                          className="py-2 text-lg px-5"
                                        >
                                          &nbsp;View Exams
                                        </Link>
                                      </DropdownItem>
                                      <DropdownItem>
                                          <button
                                            onClick={() => handleClickExam(exam)}
                                            className="text-lg py-2 px-5 rounded-lg"
                                          >
                                          {exam?.booking_status === 1 ? 'Update Booking' : 'Book Exam'  }
                                          </button>
                                      </DropdownItem>                          
                                    </DropdownItemGroup>
                                  </DropdownMenu> 
                                  :
                                  <Link
                                          onClick={() => handleUserId(exam)}
                                          data-testid="view-exam"
                                          to={`/candidate/assessments/candidate-exams/${exam?.group_id}`}
                                          className="py-2 bg-customGreen text-white  rounded-lg text-md px-5"
                                        >
                                          &nbsp;View Exams
                                  </Link>
                                    }
                                </td>               
                             </tr>                                                 
                           )  
                           })     
                          : null}                                                                                                                           
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
           </div>                                  
         </>
        :        
            <div className="text-center">
              <h5 className="bi bi-book text-center text-[60px] mt-8"></h5>
              <p className="text-center text-xl py-4">
                You don't have any mock exam yet.
              </p>
            </div>          
       }
       </div>}
    </>
  );
};

export default MockAssessment;
